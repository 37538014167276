import { getToken } from "../../JWT";
import { privateAxios } from "../axiosInstance";

export const getUserRequest = async () => {
  const header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Example header for authorization
    },
  };
  let res = await privateAxios.get("/fetch-user", header);
  return res;
};
