// Sidebar.js

import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Paper,
  Box,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import WorkIcon from "@mui/icons-material/Work";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as JobIcon } from "../../assets/icons/jobs.svg";
import { ReactComponent as QuestionIcon } from "../../assets/icons/questions.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { useDispatch } from "react-redux";
import { removeToken } from "../../service/JWT";

const Sidebar = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const Logout = () => {
    try {
      removeToken();
      dispatch({ type: "logout" });
    } catch (error) {
      console.error("Error inside Logout function", error);
    }
  };
  return (
    <Box
      sx={{
        height: "100%",
        borderRadius: "0",
        display: "flex",
        flexDirection: "column",
      }}>
      <Box
        sx={{
          flexGrow: 0,
          textAlign: "center",
          paddingTop: 2,
        }}>
        <img src="/companyLogo.png" />
      </Box>

      <Box
        sx={{
          flexGrow: 0,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "#858585",
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "center",
          }}
          paddingTop={3}>
          <List>
            <ListItem
              sx={{
                "&:hover": {
                  backgroundColor: "#F6FCFF",
                  "& svg path": {
                    fill: "#17335E", // Change to your desired color on hover
                  },
                },
              }}
              button
              component={RouterLink}
              to="/dashboard">
              <Box paddingRight={2}>
                <DashboardIcon />
              </Box>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              sx={{
                "&:hover": {
                  backgroundColor: "#F6FCFF",
                  // color: "#17335E",
                  // stroke: "#17335E",
                },
              }}
              button
              component={RouterLink}
              to="/jobs">
              <Box paddingRight={2}>
                <JobIcon />
              </Box>
              <ListItemText primary="Jobs" />
            </ListItem>
            <ListItem
              sx={{
                "&:hover": {
                  backgroundColor: "#F6FCFF",
                  // color: "#17335E",
                  // stroke: "#17335E",
                },
              }}
              button
              component={RouterLink}
              to="/create-question">
              <Box paddingRight={2}>
                <QuestionIcon />
              </Box>
              <ListItemText primary="Org Question" />
            </ListItem>
          </List>
        </Box>
        <Box
          sx={{
            justifyContent: "end",
            display: "flex",
            flexDirection: "column",
            color: "#17335E",
            fontWeight: "500",
            alignItems: "center",
            height: "70%",
          }}>
          <Box
            fullWidth
            onClick={(e) => {
              Logout();
            }}
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              gap: "3px",
              ":hover": {
                cursor: "pointer",
              },
              paddingBottom: 3,
            }}>
            <LogoutIcon />
            <Typography variant={"p"}>Logout</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
