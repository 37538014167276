import { getUserRequest } from "../../service/Api/users";
import { getToken } from "../../service/JWT";

export const getUser = () => async (dispatch) => {
  try {
    if (getToken()) {
      getUserRequest()
        .then((res) => {
          if (res.status == 200) {
            dispatch({ type: "save_user", payload: res?.data?.user });
          }
        })
        .catch((error) => {
          dispatch({ type: "save_user_fail", payload: false });
        });
    } else {
      dispatch({ type: "save_user_fail", payload: false });
    }
  } catch (err) {
    console.error("getUser...", err);
    dispatch({ type: "save_user_fail", payload: false });
  }
};
