import { Navigate, Route } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Navbar from "../components/UI/Navbar";
import { useEffect, useState } from "react";
import { Box, Grid, ThemeProvider, createTheme } from "@mui/material";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: 1000000,
  },
  content: {
    flexGrow: 1,
    padding: "28px",
  },
}));

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: "#ffff !important",
            // backgroundColor: "#ffff",
          },

          "& .MuiOutlinedInput-root": {
            "&:hover": {
              borderColor: "#ffff",
            },
            "& fieldset": {
              borderColor: "#ffff !important",
            },

            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ffff",
            },

            "& input": {
              "&:-webkit-autofill": {
                WebkitBoxShadow: "none",
                WebkitTextFillColor: "#ffff", // Customize the text color
                transition: "background-color 5000s ease-in-out 0s",
                caretColor: "#fff",
              },
            },
          },

          "& .MuiInputBase-input": {
            color: "#ffff",
          },
        },
      },
    },
  },
});

// PublicRoute component for routes accessible by everyone
const PublicRoute = ({ children, isAuthenticated, title, ...rest }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    document.title = title;
  }, [title]);
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
        }}>
        <Box sx={{ height: "100vh", width: "60%", overflow: "hidden" }}>
          <img
            src="/girl.png"
            alt="Your Image"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>

        <Box sx={{ position: "absolute", display: "flex", width: "100%" }}>
          <Grid container>
            <Grid item md={7}>
              <Box
                sx={{
                  backgroundColor: "#E1F3FF",
                  width: "100%",
                  height: "100%",
                  opacity: "0.7",
                }}></Box>
            </Grid>
            <Grid item md={5}>
              <Box
                sx={{
                  width: "100%",
                  height: "100vh",
                  backgroundColor: "#17335E",
                  textAlign: "center",
                  alignItems: "center",
                  display: "flex",
                  flexWrap: "wrap",
                  overflow: "auto",
                }}>
                <Box
                  sx={{
                    width: "100%",
                    p: 2,
                    pl: 10,
                    pr: 10,
                    color: "#fff",
                  }}>
                  {children}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PublicRoute;
