import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Suspense } from "react";
import Spinner from "./components/UI/Spinner";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./Redux/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pages from "./pages";
import ProgressModal from "./components/MODELS/ProgressModal";
function App() {
  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <div className="container">
            <Spinner />
          </div>
        }>
        <Router>
          <ToastContainer />
          <Pages />
        </Router>
      </Suspense>
    </Provider>
  );
}

export default App;
