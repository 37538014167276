import { Navigate, Route } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Navbar from "../components/UI/Navbar";
import { useEffect, useState } from "react";
import { Box, Grid, ThemeProvider, createTheme } from "@mui/material";
import Sidebar from "../components/UI/Sidebar";
import { CssBaseline } from "@mui/material";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: 1000000,
  },
  content: {
    flexGrow: 1,
    padding: "28px",
  },
}));

const theme = createTheme({
  components: {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          "& .MuiPickersCalendarHeader-root .MuiButtonBase-root .MuiSvgIcon-root path":
            {
              fill: "#17335E",
            },

          "& .MuiDayCalendar-header span": {
            color: "#17335E",
          },

          "& .MuiDayCalendar-monthContainer .MuiDayCalendar-weekContainer .MuiPickersDay-root":
            {
              color: "#17335E",
            },

          "& .MuiDayCalendar-monthContainer .MuiDayCalendar-weekContainer .MuiPickersDay-root.Mui-selected":
            {
              color: "#ffff",
            },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          boxShadow:
            "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", // Typical Material-UI elevation shadow
          backgroundColor: "#ffff", // Assuming your theme has this as a light color
          color: "#17335E", // Text color inside the tooltip
          fontSize: "1rem", // Custom font size
          borderRadius: "5px", // Rounded corners for the tooltip
          padding: "10px 15px", // Custom padding for the tooltip
          maxWidth: "430px",
        },
        arrow: {
          color: "#ffff", // Arrow color should match the tooltip background
        },
      },
    },
    MuiDigitalClock: {
      styleOverrides: {
        root: {
          "& .MuiList-root": {
            "& .MuiMenuItem-root": {
              // padding: 0,
            },
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @keyframes scan {
          0%, 100% {
            transform: translateY(0%);
          }
          50% {
            transform: translateY(calc(77vh - 5px));
          }
        }
      `,
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: "#ffff !important",
            // backgroundColor: "#ffff",
          },

          "& .MuiOutlinedInput-root": {
            "&:hover": {
              borderColor: "#ffff",
            },
            "& fieldset": {
              borderColor: "#ffff !important",
            },

            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ffff",
            },

            "& input": {
              "&:-webkit-autofill": {
                WebkitBoxShadow: "none",
                WebkitTextFillColor: "#ffff", // Customize the text color
                transition: "background-color 5000s ease-in-out 0s",
                caretColor: "#fff",
              },
            },
          },

          "& .MuiInputBase-input": {
            color: "#ffff",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#17335E",
    },
    secondary: {
      main: "#19857b",
    },
  },
});

// PublicRoute component for routes accessible by everyone
const InterviewRoute = ({ children, isAuthenticated, title, ...rest }) => {
  const classes = useStyles();
  useEffect(() => {
    document.title = title;
  }, [title]);
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default InterviewRoute;
