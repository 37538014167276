import axios from "axios";
import { getToken } from "../../JWT";
import { privateAxios } from "../axiosInstance";

export const getJobs = async ({ page, limit = 10, job_type }) => {
  const header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Example header for authorization
    },
  };
  let res = await privateAxios.get(
    `/job-listing?page=${page}&limit=${limit}&job_type=${job_type}`,
    header
  );
  return res;
};

export const createJobRequest = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Example header for authorization
    },
  };
  let res = await privateAxios.post("/create-job", data, config);
  return res;
};

export const generateQuestions = async ({
  domain_experience_id,
  jobDescription,
  jobName,
}) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Example header for authorization
    },
  };
  let res = await privateAxios.post(
    "/generate-job-questions",
    { domain_experience_id, jobDescription, jobName },
    config
  );
  return res;
};

export const getJobRequest = async (jobId) => {
  const header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Example header for authorization
    },
  };
  let res = await privateAxios.get(`/get-job?job_id=${jobId}`, header);
  return res;
};

export const updateJobRequest = async (data) => {
  const header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Example header for authorization
    },
  };
  let res = await privateAxios.post("/update-job", data, header);
  return res;
};

export const deleteJobRequest = async (jobId) => {
  const header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Example header for authorization
    },
  };
  let res = await privateAxios.patch(`/delete-job?job_id=${jobId}`, header);
  return res;
};

export const getDashboardDetails = async ({
  jobFamily = null,
  jobFunction = null,
  jobRole = null,
}) => {
  const header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Example header for authorization
    },
  };
  const res = await privateAxios.get(
    `/dashboardDetails?jobFamily=${jobFamily}&jobFunction=${jobFunction}&jobRole=${jobRole}`,
    header
  );

  return res;
};

export const jobDropdown = async () => {
  const header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Example header for authorization
    },
  };
  const urls = ["/fetchJobFamilies", "/fetchJobFunction", "/fetchJobRoles"];
  const res = await axios.all(urls.map((url) => privateAxios.get(url, header)));
  return res;
};
