import { combineReducers } from "redux";

import authReducer from "./auth/reducers";
import UserReducer from "./user/reducer";
import ModalReducer from "./modal/reducers";

export default combineReducers({
  auth: authReducer,
  user: UserReducer,
  modal: ModalReducer,
});
