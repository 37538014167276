import { getToken } from "../../JWT";
import { privateAxios } from "../axiosInstance";

export const fetchExperienceRequest = async () => {
  const header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Example header for authorization
    },
  };
  let res = await privateAxios.get("/fetch-experiecne", header);
  return res;
};

export const fetchExperienceQuestionsRequest = async ({
  experience_id,
  page = 1,
  limit = 10,
}) => {
  const header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Example header for authorization
    },
  };
  let res = await privateAxios.get(
    `/fetch-experiecne-questions?experience_id=${experience_id}&page=${page}&limit=${limit}`,
    header
  );
  return res;
};

export const createExperienceQuestion = async (data) => {
  const header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Example header for authorization
    },
  };
  let res = await privateAxios.post(
    `/create-experience-question`,
    data,
    header
  );
  return res;
};

export const updateExperienceQuestion = async (data) => {
  const header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Example header for authorization
    },
  };
  let res = await privateAxios.post(
    `/update-experience-question`,
    data,
    header
  );
  return res;
};

export const deleteExperienceQuestion = async ({ question_id }) => {
  const header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`, // Example header for authorization
    },
  };
  let res = await privateAxios.get(
    `/delete-experience-question?question_id=${question_id}`,
    header
  );
  return res;
};
