import React from "react";
import { Modal, Backdrop, Fade, LinearProgress } from "@mui/material";

const ProgressModal = ({ open, handleClose, progress }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Fade in={open}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            outline: "none",
          }}>
          <h2 id="modal-title">Progress</h2>
          <LinearProgress
            variant="determinate"
            value={progress}
            style={{ width: "100%", marginTop: "10px" }}
          />
        </div>
      </Fade>
    </Modal>
  );
};

export default ProgressModal;
