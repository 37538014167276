const initialState = {
  user: {},
  loading: true,
  isAuth: null,
  lastRoute: "/",
};

export default function UserReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "save_user":
      return {
        ...state,
        user: payload,
        isAuth: true,
        loading: false,
      };
    case "save_user_fail":
      return {
        ...state,
        isAuth: false,
        loading: false,
      };
    case "logout":
      return {
        ...state,
        isAuth: false,
      };
    case "navigation":
      return {
        ...state,
        lastRoute: payload,
      };
    default:
      return state;
  }
}
