import axios from "axios";
import { API_URL } from "../../utils/env";
import { getToken } from "../JWT";

// Create a new Axios instance for public API requests
export const publicAxios = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  // Add any default headers for public requests
});

// Create a new Axios instance for private API requests
export const privateAxios = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`, // Example header for authorization
  },
  // Add any default headers for private requests (e.g., authentication token)
});
