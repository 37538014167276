const initialState = {
  isProgressModal: null,
  progress: null,
  isSpinner: null,
  isDelete: false,
  deleteMsg: null,
  deleteType: null,
  deleteId: null,
};

export default function ModalReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "progress_modal":
      return {
        ...state,
        isProgressModal: payload?.isProgressModal,
        progress: payload?.progress,
      };
    case "spinner_modal":
      return {
        ...state,
        isSpinner: payload?.isSpinner,
      };

    case "delete_modal":
      return {
        ...state,
        isDelete: payload?.isDelete,
        deleteMsg: payload?.deleteMsg,
        deleteType: payload?.deleteType,
        deleteId: payload?.deleteId,
      };
    default:
      return state;
  }
}
