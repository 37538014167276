// Navbar.js

import React from "react";
import { AppBar, Toolbar, Typography, IconButton, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { removeToken } from "../../service/JWT";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { ReactComponent as SidebarIcon } from "../../assets/icons/sidebaricon.svg";

const Navbar = ({ onSidebarToggle, classes, isAuthenticated }) => {
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.user);
  return isAuthenticated ? (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{
            ":hover": {
              background: "none",
            },
          }}
          onClick={onSidebarToggle}>
          <SidebarIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  ) : (
    <AppBar position="static">
      <Toolbar>
        {/* Company Logo */}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
            Webspero
          </Link>
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
